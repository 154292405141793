import { Component, OnInit } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStoreService } from '../services/localstore.service';
import { UtilityService } from '../services/utility.service';
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { KioskapiService } from '../services/kioskapi.service';
import { images_display_timer, terminalCheck } from '../common/api';
import { TranslateConfigService } from '../services/translate-config.service';
import { DOMAIN_URL } from '../common/api';


@Component({
  selector: 'app-scan',
  templateUrl: './scan.page.html',
  styleUrls: ['./scan.page.scss'],
})
export class ScanPage implements OnInit {
  is_maintaince_mode_on;
  appSettings: any;
  shop: any;
  menuItems={};
  totalAmount: number;
  settings={};
  orderType = "";
  currency = "¥";
  showDecimal= true;
  cartItems: [];
  cartItemCount: number;
  totalCartAmount: number = 0;
  payment:any;
  timer_start=true;
  telemetry_poller:any;
  terminal_modal;
  is_restaurant_closed_status;
  store_close_status;
  restaurant_mode;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  store_info;
  is_ella_maintaince_mode_on:boolean
  obj_restaurantDetails;
  kiosk_start_screen_image:any;
  kiosk_scan_screen_gif:any;
  display_images:any;
  menuItems_timer:any;
  enable_for_kiosk="";
  QR_code_id = 'https://order.ellaapp.com/admin/web/referfriend';
  useLocalGraphics = false;
  useOldDesign='';
  isSapRes = false;


  constructor(private router: Router,
    private store:LocalStoreService,
    private utilityService: UtilityService,
    private kioskauthservice:KioskauthService,
    private kiosk_apiService : KioskapiService,
    public alertController: AlertController,
    private translateConfigService: TranslateConfigService) { }
  
  ngOnInit() {
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);
    this.is_restaurant_closed();
        this.getRestaurantDetails();
        this.store_close_status = setInterval(
          () => {
            this.is_restaurant_closed();
          }
          , 10000);
    
        this.restaurant_mode = setInterval(
            () => { 
              this.getRestaurantDetails();
            }
            , 12000);
            // this.display_images =setInterval(()=>{
            //   this.getAppSettings();
            // },images_display_timer)
      
    this.getAppSettings();
    this.kiosk_apiService.logEvents("SCAN");
    this.kiosk_apiService.audit_log("SCAN");
  }
  checkForNetwork() {
    console.log('checkForNetwork called')
    window.addEventListener('offline', () => {
      //Do task when no internet connection
      console.log('window is offline')
      });
      window.addEventListener('online', () => {
      //Do task when internet connection returns
      console.log('window is online')
      let Autologin = this.store.get('autoLoginUrl')
      this.store.hardlogout()
      location.href = DOMAIN_URL + Autologin;
      });
  }

  ionViewWillEnter() {
    this.isSapRes = this.store.get('restaurant').master_restaurant_uuid == 'b16c8ede916a2762ba8702263e541f45';
  }

 
  getRestaurantDetails(){
   this.obj_restaurantDetails = this.kiosk_apiService.restaurant_Details()
        .subscribe((res) => {
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant',res.aaData);
          this.store_info = res.aaData;
          this.enable_for_kiosk =  res.aaData.enable_kiosk
          if(this.store_info.master_restaurant_status == "ELLA_M_ON"){
            this.is_ella_maintaince_mode_on = true;
          }
          else{
            this.is_ella_maintaince_mode_on = false;
          }
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          //this.getAppSettings();
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          if(this.shop.master_restaurant_status == 'M_ON'){
          this.is_maintaince_mode_on ='true';
          }
          else{
          this.is_maintaince_mode_on = 'false';
          }
        } 
    }, 
      // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader.
      err =>
          {
            this.technical_error();
    });
  }
  
  getAppSettings() {

   this.kiosk_apiService.appsettings().subscribe(response => {
      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;

        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description :  environment.M_ON;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
        this.kiosk_start_screen_image = response.aaData.app_settings.app_store_scan_description_kiosk;
        this.kiosk_scan_screen_gif = response.aaData.app_settings.kiosk_scan_screen_gif;
        if (response.aaData.app_settings.kiosk_use_old_design) {
          this.useOldDesign = 'true';
        } else {
          this.useOldDesign = 'false';
        }
        if (response.aaData.app_settings.kiosk_use_local_graphic) {
          this.useLocalGraphics = response.aaData.app_settings.kiosk_use_local_graphic;
        } else {
          this.useLocalGraphics = false;
        }
      }
    }, err => {

    });
  }

  go_to_maintenance() {
    location.href = "http://192.168.10.11:9001";
  }

  obj_restaurantstatus;
  is_restaurant_closed(){
   this.obj_restaurantstatus = this.kiosk_apiService.restaurant_Operational()
      .subscribe(
          (res) => {
              if (res.statusCode == 200) {
                if(res.aaData == true) {
                  this.is_restaurant_closed_status= "NO";
                } else {                  
                  this.is_restaurant_closed_status= "YES";
                }
              } 
          },
          err =>
          {
            this.technical_error();
          }
      );
 }

 ionViewDidLeave() {
  clearInterval(this.restaurant_mode);
  clearInterval(this.store_close_status);
  clearInterval(this.telemetry_poller);
  this.obj_restaurantstatus.unsubscribe();
  this.obj_restaurantDetails.unsubscribe();
  clearInterval(this.terminal_modal)
  clearInterval(this.display_images)
}

async technical_error(){
  const alert =  this.alertController.create({
    cssClass: 'my-custom-class',
    mode: 'md',
    header: 'Oops!',//'Please Tap Again',
    message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
    buttons: [
    {
     text: '',
     cssClass: 'cross',
    handler: () => {
     this.alertController.dismiss();
    this.router.navigateByUrl('/Scan');
     }
   }
    ]
    });
     setTimeout(() => { this.router.navigateByUrl('/Scan'),this.alertController.dismiss();},5000);
     (await alert).present();
  }
  redirectToHome(){
    this.router.navigate([`home`]);
  }
}
