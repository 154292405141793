import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateConfigService } from '../services/translate-config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { API_URL2, DOMAIN_URL, KIOSK_AUTO_LOGIN, PRINT_API_URL, settlement_timer } from '../common/api';
import { API_URL, PRINT_TIMEOUT, setIntervalTimeOut } from '../common/api';
import { ApiService } from '../services/api.service';
import { PageLoaderService } from '../services/page-loader.service';
import { KioskapiService } from '../services/kioskapi.service';

@Component({
  selector: 'app-settlement-complete',
  templateUrl: './settlement-complete.page.html',
  styleUrls: ['./settlement-complete.page.scss'],
})
export class SettlementCompletePage implements OnInit {
  orderInfo: any = [];
  paymentResponse: any = [];
  currency = "¥";
  showDecimal= true;
  print_url;
  render_order_number:any = "";
  order_info_uuid: string;
  card_idi: string;
  bal: string;
  QR_order_info_uuid: string;
  printUrl: string;
  time_left_counter : any;
  time_left = 0;
  time_stop = false;
  mid_cancel = false;
  messageSuccess = true;
  ORDER_UUID;
  translatedText;

  constructor(
    private httpClient: HttpClient,
    private api: ApiService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private kioskauthservice:KioskauthService,
    private route: ActivatedRoute,
    private store:LocalStoreService,
    public alertController: AlertController,
    private kiosk_apiService : KioskapiService,
    private translateConfigService: TranslateConfigService,
    private pageLoaderService: PageLoaderService
    ) { 
      this.translateConfigService.getDefaultLanguage(); 
      this.showDecimal= true;
      if(this.store.getCountry()) {
          let countryData = this.store.getCountry();
          this.currency   = countryData['master_country_currency_code'];
          if(countryData['master_country_currency_decimal_places'] == 0){
              this.showDecimal= false;
          }
      }
      this.ORDER_UUID = this.route.snapshot.queryParamMap.get('id');
      this.getQRCode();
    }
  
    ngOnInit() {


    this.kiosk_apiService.logEvents("SETTLEMENT");
    this.kiosk_apiService.audit_log("SETTLEMENT");

      this.time_left = settlement_timer
  
      this.time_left_counter = setInterval(()=>{
        this.time_left -= 1;
        if (this.time_left == 0) {
          clearInterval(this.time_left_counter);
          this.store.setUserCart([]); 
          this.store.remove("formFields");
          this.store.remove("cart");
          this.alertController.dismiss();
          let Autologin = this.store.get('autoLoginUrl')
          this.store.hardlogout()
          location.href = DOMAIN_URL + Autologin;
          // return false;
        } 
      }, setIntervalTimeOut);
      
    }
  
    getQRCode() {
      this.ORDER_UUID = this.route.snapshot.queryParamMap.get('id');
      this.order_info_uuid = localStorage.getItem('ellaOrderId');
      this.QR_order_info_uuid = localStorage.getItem('ellaOrderId') + '.';
      if (this.route.snapshot.queryParamMap.get('id')) {
        this.order_info_uuid = this.route.snapshot.queryParamMap.get('id');
        this.card_idi = this.route.snapshot.queryParamMap.get('c');
        this.bal = this.route.snapshot.queryParamMap.get('b');
        this.QR_order_info_uuid = this.route.snapshot.queryParamMap.get('id') + '.';
      }
      this.kioskauthservice.apiReq('post', 'orderinfo/getbyuuid', { order_info_uuid: this.ORDER_UUID })
        .subscribe(res => this.order_info_uuid = res?.aaData?.order_number);
        this.getOrderDetails();
    }

    getSantizeUrl(url : string) { 
      return this.sanitizer.bypassSecurityTrustUrl(url); 
    }

    auto_print() {
      let purl=API_URL2+"restapi/newinvoice?oid="+this.orderInfo?.order_number;
      this.printUrl = PRINT_API_URL + purl;
      this.api.printPdf( PRINT_API_URL + purl)
        .subscribe(res => {
        },
        error =>{
        })

        setTimeout(
          ()=>{
            this.store.setUserCart([]); 
            this.store.setUserCart([]); 
            this.store.remove("formFields");
            this.store.remove("cart");
            this.alertController.dismiss();
          }
        , PRINT_TIMEOUT);
    }
  
    ionViewWillEnter(){ 
      this.getTranslatedText();
    }
    getTranslatedText() {
      this.translatedText = this.store.get('translatedText');
    }
  
    getOrderDetails(){
      let order_info_uuid = this.route.snapshot.queryParamMap.get('id');
      let data = {
        order_info_uuid: order_info_uuid
      };
      this.kiosk_apiService.order_details(data)
        .subscribe(res => {

          this.orderInfo = res.aaData;
          let render_order_number1 =  this.orderInfo?.order_number.split("_");
          if(render_order_number1[1] !=null && render_order_number1[1] !=undefined){
            let render_order_number = render_order_number1[1];
            this.render_order_number = render_order_number;
          }
          this.auto_print();
        },
        error =>{

        })
    }
  
    goBack(){
      this.store.setUserCart([]); 
      this.router.navigate([`home`]);
    }

    check_balance(){
        this.api.balance_check().subscribe(
          (r)=>{
            if (r['balance']){
              this.paymentResponse  = r;
            }      
          }
        );
    }
  
    callPaymentApi(orderUuid){
      let res: any = [];
      this.updateOrderStatus(orderUuid,res);
    }
  
    updateOrderStatus(orderUuid,res){
      let data = {
        order_info_uuid: orderUuid,
        restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
        payment_response: res
      }

      this.kiosk_apiService.update_order(data).subscribe(res => {

        if(res.statusCode == 200) {
          this.store.remove("orderId");
          this.store.remove("totalAmount");
          this.store.remove("totalCartAmount");
          this.store.setCart([]); 
          this.store.remove("selectedItemsInCart"); 
          this.router.navigate([`settlement-complete`]);

        }   
      },
      error =>{

      }
      )
    }
  

    go_to_maintenance() {
      location.href = "http://192.168.10.11:9001/maintenance";
    }

    redirectToHome(){
      this.store.remove('sessionId')
      this.store.setUserCart([]); 
      this.store.remove("formFields");
      this.store.remove("cart");
      this.alertController.dismiss();
      let Autologin = this.store.get('autoLoginUrl')
      this.store.hardlogout()
      location.href = DOMAIN_URL + Autologin;
    }

    ionViewDidLeave(){
    
    }
  }
