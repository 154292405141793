import { Component } from '@angular/core';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from 'src/environments/environment';
import { LocalStoreService } from './services/localstore.service';
import { DOMAIN_URL } from './common/api';
import { DisableRightClickService } from './services/disable-right-click.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private store:LocalStoreService, private rightClickDisable: DisableRightClickService) {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);
    this.checkForNetwork();
    this.rightClickDisable.disableRightClick();
  }
  checkForNetwork() {
    console.log('checkForNetwork called')
    window.addEventListener('offline', () => {
      //Do task when no internet connection
      console.log('window is offline')
      });
      window.addEventListener('online', () => {
      //Do task when internet connection returns
      console.log('window is online')
      let Autologin = this.store.get('autoLoginUrl')
      this.store.hardlogout()
      location.href = DOMAIN_URL + Autologin;
      });
  }
}
