export const API_URL = 'https://staging.crowndigital.io/admin/web/v1/';
export const API_URL2 = 'https://staging.crowndigital.io/admin/web/';
//export const LOCAL_PAYMENT_API_URL = "https://192.168.10.11";
export const LOCAL_PAYMENT_API_URL = "http://localhost";
export const LOCAL_PAYMENT_PORT = "5000";
export const SUICA_SERVER = "7700";
export const PRINT_TIMEOUT = 20000;
export const telemetryTimeout = 5000;
export const setIntervalTimeOut =1000;
export const QRcodeTimeout = 10000;
export const cart_time = 30;
export const payment_timer = 30;
export const modalTimeout = 6000;
export const PRINT_API_URL = "http://localhost:3333/?purl=";
export const KIOSK_AUTO_LOGIN ="https://devchangit2kiosk.crowndigital.io/kioskautologin/91e2cbb46dc9d0c42f628e97f4482410/69805d2ce824233507a17ed0023fa33c";
export const page_redirection_time = 30;
export const apitimeout = 69000;
export const cancel_timeout = 10000;
export const enable_test_buttons = true;
export const payment_failed_modal = 30;
export const settlement_timer = 30;
export const terminalCheck = 10000;
export const terminal_check_enable = false;
export const menu_items_timer = 20000;
export const images_display_timer = 15000;
export const DOMAIN_URL = "https://devkiosk.crowndigital.io";
export const qr_page_redirection_time = 60;
export const qr_page_retry_modal_time = 15;
export const card_page_redirection_time = 70;
export const dbs_qr_timer = 120;
export const dbs_qr_timer_res_wait = 60;

