import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PageLoaderService } from './page-loader.service';
import { API_URL,LOCAL_PAYMENT_API_URL, LOCAL_PAYMENT_PORT } from '../common/api';
import { LocalStoreService } from '../services/localstore.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class UtilityService {   

  Orderstatus = new Subject();
  Orderuuid = new Subject();
  confirmationResult: any;
  constructor(
    private httpClient: HttpClient,
    private toastController: ToastController,
    private pageLoaderService: PageLoaderService,
    private router: Router,
    private store: LocalStoreService,
    private modalController: ModalController,
    private translate: TranslateService,
    public alertController: AlertController,
  ) {
    
   }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     // eslint-disable-next-line @typescript-eslint/naming-convention
  //     'Content-Type':  'application/json',
  //     // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
  //     // eslint-disable-next-line @typescript-eslint/naming-convention
  //     'Accept-Language': this.store.getLanguageUuid()//'d12cc4046dbc53630c2ba3afcb18640c'
  //   })
  // };

  apiReq(method: string, endPoint: string, payload?: any): Observable<any> {
     let options: any = {
                            headers: new HttpHeaders({
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              'Content-Type':  'application/json',
                              // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              'Accept-Language': this.store.getLanguageUuid(),
                              'Accept-Language-Code': this.store.getLanguageCode()
                            })
                        }
    switch (method) {
      case 'get':
        return this.httpClient.get(API_URL + endPoint, options );
      case 'post':
        return this.httpClient.post(API_URL + endPoint, payload,options);
      case 'put':
        return this.httpClient.put(API_URL + endPoint, payload);
      case 'patch':
        return this.httpClient.patch(API_URL + endPoint, payload);
      case 'delete':
        return this.httpClient.delete(API_URL + endPoint, payload);
      default:
        return null;
    }
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2100,
      position: 'top',
    });
    toast.present();
  }


  onSuccess(message): void {
    this.pageLoaderService.pageLoader(false);//hide page loader
    this.pageLoaderService.setLoaderText('');//setting loader text empty
    //this.toastrManager.success(message, 'Success!'); //showing success toaster
    this.presentToast(message);
  }

  onError(message): void {
    this.pageLoaderService.setLoaderText('');//setting loader text
    this.pageLoaderService.pageLoader(false);//hide page loader
    //this.toastrManager.error(message, 'Error!');//showing error toaster message
    this.presentToast(message);
  }

  showPageLoader(message = ''): void {
    this.pageLoaderService.pageLoader(true,message);//show page loader
    if (message.length > 0) {
      this.pageLoaderService.setLoaderText(message);//setting loader text
    }
  }

  hidePageLoader(): void {
    this.pageLoaderService.pageLoader(false);//hide page loader
    this.pageLoaderService.setLoaderText('');//setting loader text
  }

  login(postedData): Observable<any> {
    // return this.httpClient.post('auth/login', postedData, { observe: 'response' })
    return this.httpClient.post('auth/login', postedData);
  }

  halt_maintenance(c){

  }

  payment_server: any;
  wait_for_payment(order_item_id: string, subtotal_in_dollar: number, id: string ) {
    // demux kiosk id=1 to port 3101, id=2 to port 3201 etc...
    // diff gateway executables will listen on these ports
    /*
    this.payment_server = "http://192.168.10.11:81";    
    if ( (id=='7') || (id=='8') )
    {
      this.payment_server = "http://localhost:7000";
    }
    if ( (id=='3') || (id=='4') )
    {
      this.payment_server = "http://192.168.10.11:7000";
    }
    */
    this.payment_server = LOCAL_PAYMENT_API_URL+":"+LOCAL_PAYMENT_PORT;

    if ( (id=='5') || (id=='6') )
    {
      subtotal_in_dollar = 1;
      if (id == '5') id = '1';
      if (id == '6') id = '2';
    }
    //subtotal_in_dollar = 1;
    console.log("dollars charged: "+ (subtotal_in_dollar).toString() );
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', LOCAL_PAYMENT_API_URL);

    return this.httpClient.post(
      this.payment_server + '/api/Adyen/PaymentRequest?amount='+(subtotal_in_dollar).toString()+'&Terminalid=T'+id+'&ServiceID='+order_item_id, 
      {},
      { headers, responseType: 'text'}
    );
  }




  
  
}